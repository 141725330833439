import { lazy } from 'react';

// project imports
import { ROLE } from 'constant/auth';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import { routeFeatures } from './navigate';
import NavMotion from 'layout/NavMotion';
import AuthGuard from 'utils/route-guard/AuthGuard';

const PageSetupConfigs = Loadable(lazy(() => import('views/pages/merchant/setup-configs')));

// ==============================|| AUTH ROUTING ||============================== //

const SetupRoutes = {
    element: (
        <NavMotion>
            <AuthGuard>
                <MinimalLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        {
            path: routeFeatures.setupConfigs(),
            element: <PageSetupConfigs />,
            roles: [ROLE.Merchant]
        }
    ]
};

export default SetupRoutes;
