import { setSession, verifyToken } from 'contexts/JWTContext';
import { KeySearchParamsEnum } from 'enums';
import useAuth from 'hooks/useAuth';
import useFilter from 'hooks/useFilter';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from 'store/actions';
import axiosServices from 'utils/axios';
import URL from 'utils/url-page';

const OAuth2RedirectHandler = () => {
    const { getValueFilter, dependenciesEffect } = useFilter();
    const { dispatch } = useAuth();
    const navigate = useNavigate();

    const token = useMemo(() => getValueFilter(KeySearchParamsEnum.TOKEN), [dependenciesEffect]);

    const loginOAuth = async (serviceToken: string | null) => {
        try {
            if (serviceToken && verifyToken(serviceToken)) {
                setSession(serviceToken);
                const response = await axiosServices.get('/v1/auth');
                const user = response.data;
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user
                    }
                });
                return;
            }

            throw new Error('Can not login oAuth');
        } catch (err) {
            navigate(URL.auth.login);
        }
    };

    useEffect(() => {
        loginOAuth(token);
    }, [token]);

    return null;
};

export default OAuth2RedirectHandler;
