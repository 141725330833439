import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD, MERCHANT } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { ROLE } from 'constant/auth';
import { routeFeatures } from 'routes/navigate';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            switch (user?.roleId) {
                case ROLE.Admin:
                    navigate(DASHBOARD);
                    break;
                case ROLE.Operator:
                    navigate(DASHBOARD);
                    break;

                default:
                    navigate(user?.setupConfig ? MERCHANT : routeFeatures.setupConfigs());
                    break;
            }
        }
    }, [isLoggedIn, navigate, user?.roleId, user]);

    return children;
};

export default GuestGuard;
