import React, { createContext, useEffect, useReducer, useRef } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import accountReducer from 'store/accountReducer';
import { LOGIN, LOGOUT } from 'store/actions';

// project imports
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
// types
import { SERVICE_TOKEN } from 'constant/auth';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { dispatch as dp } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { KeyedObject } from 'types';
import { InitialLoginContextProps, JWTContextType } from 'types/auth';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem(SERVICE_TOKEN, serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem(SERVICE_TOKEN);
        delete axios.defaults.headers.common.Authorization;
    }
};

export const getToken = () => {
    const serviceToken = window.localStorage.getItem(SERVICE_TOKEN);
    return serviceToken;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType>({
    ...initialState,
    dispatch: () => {},
    firebaseFacebookSignIn: null,
    firebaseGoogleSignIn: null,
    getCode: () => {},
    getCodeForgotPassword: () => {},
    init: () => {},
    login: (username: string, password: string) => new Promise(() => {}),
    logout: () => {},
    register: (email: string, password: string, countryCode: string, referCode: string, phone: string) => new Promise(() => {}),
    resetPassword: () => {},
    updateProfile: () => {},
    verifyCodeRegister: () => {}
});

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const interval = useRef<NodeJS.Timeout | null>(null);
    const navigate = useNavigate();

    const init = async () => {
        try {
            const serviceToken = getToken();
            if (serviceToken && verifyToken(serviceToken)) {
                setSession(serviceToken);
                const response = await axios.get('/v1/auth');
                const user = response.data;
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user
                    }
                });
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            dispatch({
                type: LOGOUT
            });
        }
    };

    const refreshToken = async () => {
        try {
            const response = await axios.get('/v1/auth/refresh');
            setSession(response.data);
        } catch (error) {
            dispatch({
                type: LOGOUT
            });
        }
    };
    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (state.isLoggedIn) {
            interval.current = setInterval(() => {
                refreshToken();
            }, 45 * 60 * 1000);
        }

        return () => {
            interval.current && clearInterval(interval.current);
        };
    }, [state.isLoggedIn]);

    const login = async (username: string, password: string) => {
        try {
            const response = await axios.post('/v1/auth', { username, password });

            if (response.data?.status === 0) {
                dp(
                    openSnackbar({
                        open: true,
                        message: 'Your account not yet verify!',
                        variant: 'alert',
                        alert: {
                            color: 'error',
                            severity: 'error'
                        },
                        close: true,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                );
                setTimeout(() => {
                    navigate('/verify-account');
                }, 2500);
                return;
            }

            if (response.data?.token) {
                setSession(response.data?.token);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.data
                    }
                });
            } else {
                dp(
                    openSnackbar({
                        open: true,
                        message: 'Authenticated failed',
                        variant: 'alert',
                        alert: {
                            color: 'error',
                            severity: 'error'
                        },
                        close: true,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                );
            }
        } catch (error) {
            dp(
                openSnackbar({
                    open: true,
                    message: 'Authenticated failed',
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        severity: 'error'
                    },
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            );
        }
    };

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase
            .auth()
            .signInWithPopup(provider)
            .then(async (result: any) => {
                const response = await axios.post('/v1/auth/authByThirdParty', {
                    email: result?.additionalUserInfo?.profile?.email,
                    deviceKey: '',
                    avatar: result?.additionalUserInfo?.profile?.picture,
                    thirdParty: 'Google',
                    full_name: result?.additionalUserInfo?.profile?.name
                });
                if (response) {
                    setSession(response?.data?.token);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: response?.data
                        }
                    });
                } else {
                    dp(
                        openSnackbar({
                            open: true,
                            message: 'Authenticated failed',
                            variant: 'alert',
                            alert: {
                                color: 'error',
                                severity: 'error'
                            },
                            close: true,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        })
                    );
                }
            })
            .catch((error) => {
                dp(
                    openSnackbar({
                        open: true,
                        message: 'Authenticated failed',
                        variant: 'alert',
                        alert: {
                            color: 'error',
                            severity: 'error'
                        },
                        close: true,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                );
            });
    };

    const firebaseFacebookSignIn = () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then(async (result: any) => {
                const response = await axios.post('/v1/auth/authByThirdParty', {
                    email: result?.additionalUserInfo?.profile?.email,
                    deviceKey: '',
                    avatar: result?.additionalUserInfo?.profile?.picture?.data?.url,
                    thirdParty: 'Facebook',
                    full_name: result?.additionalUserInfo?.profile?.name
                });

                setSession(response?.data?.token);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response?.data
                    }
                });
            })
            .catch((error) => {
                dp(
                    openSnackbar({
                        open: true,
                        message: 'Authenticated failed',
                        variant: 'alert',
                        alert: {
                            color: 'error',
                            severity: 'error'
                        },
                        close: true,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                );
            });
    };

    const register = async (username: string, password: string, countryCode: string, referCode: string, phone: string) => {
        // todo: this flow need to be recode as it not verified
        try {
            console.log('before call api');

            const response = await axios.post('/v1/auth/register', {
                username,
                password,
                countryCode,
                referCode,
                phone
            });
            console.log({ response });

            // const { mobile, email } = response.data.data as UserProfile;
            if (response) {
                window.localStorage.setItem('username', username);
                dp(
                    openSnackbar({
                        open: true,
                        message: 'Register successfully and then your account must be verify!',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                );
                setTimeout(() => {
                    navigate('/verify-account', {
                        replace: true,
                        state: {
                            isSend: true
                        }
                    });
                }, 2000);
            }
        } catch (error: any) {
            dp(
                openSnackbar({
                    open: true,
                    message: error?.message,
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        severity: 'error'
                    },
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            );
            setTimeout(() => {
                navigate('/login', { replace: true });
            }, 1500);
        }
    };

    const getCode = async (username: string | null | undefined) => {
        try {
            await axios.post('/v1/auth/getVerifyCode', { username });

            dp(
                openSnackbar({
                    open: true,
                    message: 'Send code success',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            );
        } catch (error) {
            dp(
                openSnackbar({
                    open: true,
                    message: 'Send code error',
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        severity: 'error'
                    },
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            );
        }
    };
    const mVerifyCode = useMutation((model: { username: string | null; verifyCode: string }) => axios.post('/v1/auth/verifyCode', model));
    const verifyCodeRegister = async (username: string | null, verifyCode: string) => {
        mVerifyCode.mutate(
            { username: username ?? '', verifyCode },
            {
                onSuccess(data, variables, context) {
                    if (data?.status === 200) {
                        dp(
                            openSnackbar({
                                open: true,
                                message: 'Verify success',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: true,
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }
                            })
                        );
                        navigate('/login', { replace: true });
                    } else {
                        console.log('else error');
                        dp(
                            openSnackbar({
                                open: true,
                                message: 'Verify failed',
                                variant: 'alert',
                                alert: {
                                    color: 'error',
                                    severity: 'error'
                                },
                                close: true,
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }
                            })
                        );
                    }
                },
                onError(error: any, variables, context) {
                    dp(
                        openSnackbar({
                            open: true,
                            message: 'Verify failed',
                            variant: 'alert',
                            alert: {
                                color: 'error',
                                severity: 'error'
                            },
                            close: true,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        })
                    );
                }
            }
        );
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
        localStorage.clear();
        sessionStorage.clear();
    };

    const getCodeForgotPassword = async (username: string) => {
        window.localStorage.setItem('username', username);
        try {
            const res = await axios.post('/v1/auth/getVerifyCode', { username });

            if (res.status === 200) {
                dp(
                    openSnackbar({
                        open: true,
                        message: 'Send code successfully ',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                );
                navigate('/change-password', { replace: true });

                return;
            }
            dp(
                openSnackbar({
                    open: true,
                    message: 'Send code error',
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        severity: 'error'
                    },
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            );
        } catch (error) {
            dp(
                openSnackbar({
                    open: true,
                    message: 'Send code error',
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        severity: 'error'
                    },
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            );
        }
    };

    const resetPassword = async (username: string, verifyCode: string, newPassword: string, newPasswordConfirm: string) => {
        try {
            await axios.post('v1/auth/changePassword', { username, verifyCode, newPassword, newPasswordConfirm });
            dp(
                openSnackbar({
                    open: true,
                    message: 'Change password successfully!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            );
            navigate('/login', { replace: true });
        } catch (error: any) {
            dp(
                openSnackbar({
                    open: true,
                    message: error?.message,
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        severity: 'error'
                    },
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            );
        }
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                init,
                login,
                firebaseGoogleSignIn,
                firebaseFacebookSignIn,
                logout,
                register,
                verifyCodeRegister,
                getCode,
                resetPassword,
                updateProfile,
                getCodeForgotPassword,
                dispatch
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
